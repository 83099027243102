<template>
  <el-dialog
    title="Filter"
    :visible.sync="reportsStore.filterDialog"
    width="35%"
    top="30px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align="center"
    ref="elDialogRef"
  >
    <el-row :gutter="8" class="filter-body">
      <el-col :span="24">
        <el-form :model="form">
          <!-- ? -->
          <el-form-item>
            <el-col :span="12">
              <el-form-item prop="start_date2" label="Ketgan sana">
                <el-input
                  clearable
                  v-model="form.start_date2"
                  v-mask="'####-##-##'"
                  placeholder="Ketgan sana"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="contract__client__full_name__icontains"
                label="Kimning yuki"
              >
                <el-input
                  clearable
                  v-model="form.contract__client__full_name__icontains"
                  placeholder="Kimning yuki"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item label="Пункт отправки">
                <el-input
                  clearable
                  v-model="form.destinations['1'].city_name"
                  placeholder="Пункт отправки"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="zayavka_distance" label="Km">
                <el-input
                  clearable
                  v-model="form.zayavka_distance"
                  placeholder="Km"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 1">
                <el-input
                  clearable
                  v-model="form.destinations['2'].city_name"
                  placeholder="Пункт назначения 1"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 2">
                <el-input
                  clearable
                  v-model="form.destinations['3'].city_name"
                  placeholder="Пункт назначения 2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 3">
                <el-input
                  clearable
                  v-model="form.destinations['4'].city_name"
                  placeholder="Пункт назначения 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пункт назначения 4">
                <el-input
                  clearable
                  v-model="form.destinations['5'].city_name"
                  placeholder="Пункт назначения 4"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__model__icontains"
                label="Mashina modeli"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__model__icontains"
                  placeholder="Mashina modeli"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__brand__name__icontains"
                label="Mashina brand"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__brand__name__icontains"
                  placeholder="Mashina brand"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="main_driver__staff__staffpersonal__first_name__icontains"
                label="Asosiy haydovchi (Ism)"
              >
                <el-input
                  clearable
                  v-model="
                    form.main_driver__staff__staffpersonal__first_name__icontains
                  "
                  placeholder="Asosiy haydovchi (Ism)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="main_driver__staff__staffpersonal__last_name__icontains"
                label="Asosiy haydovchi (Familiya)"
              >
                <el-input
                  clearable
                  v-model="
                    form.main_driver__staff__staffpersonal__last_name__icontains
                  "
                  placeholder="Asosiy haydovchi (Familiya)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="assistant_driver__staff__staffpersonal__first_name__icontains"
                label="Yordamchi haydovchi (Ism)"
              >
                <el-input
                  clearable
                  v-model="
                    form.assistant_driver__staff__staffpersonal__first_name__icontains
                  "
                  placeholder="Yordamchi haydovchi (Ism)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="assistant_driver__staff__staffpersonal__last_name__icontains"
                label="Yordamchi haydovchi (Familiya)"
              >
                <el-input
                  clearable
                  v-model="
                    form.assistant_driver__staff__staffpersonal__last_name__icontains
                  "
                  placeholder="Yordamchi haydovchi (Familiya)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="truck__reg_number_region__icontains"
                label="Truck region number"
              >
                <el-input
                  clearable
                  v-model="form.truck__reg_number_region__icontains"
                  placeholder="Truck region number"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="truck__reg_number__icontains"
                label="Truck nomber"
              >
                <el-input
                  clearable
                  v-model="form.truck__reg_number__icontains"
                  placeholder="Truck nomber"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__price_per_km"
                label="Asosiy haydovchi (km narx)"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__price_per_km"
                  placeholder="Asosiy haydovchi (km narx)"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="truck__truck_type__price_per_km_for_assistant"
                label="Yordamchi haydovchi (km narx)"
              >
                <el-input
                  clearable
                  v-model="form.truck__truck_type__price_per_km_for_assistant"
                  placeholder="Yordamchi haydovchi (km narx)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">Tozalash</el-button>
      <el-button @click="onSubmit" type="primary" style="color: white"
        >Izlash</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { takeFilledKeyValue } from '@/utils'

const formDefaultKeys = () => ({
  start_date2: null,
  contract__client__full_name__icontains: null,
  truck__truck_type__model__icontains: null,
  truck__reg_number__icontains: null,
  zayavka_distance: null,
  main_driver__staff__staffpersonal__first_name__icontains: null,
  truck__truck_type__brand__name__icontains: null,
  main_driver__staff__staffpersonal__last_name__icontains: null,
  assistant_driver__staff__staffpersonal__first_name__icontains: null,
  assistant_driver__staff__staffpersonal__last_name__icontains: null,
  truck__reg_number_region__icontains: null,

  destinations: {
    1: {
      city_name: '',
      order: 1
    },

    2: {
      city_name: '',
      order: 2
    },

    3: {
      city_name: '',
      order: 3
    },

    4: {
      city_name: '',
      order: 4
    },

    5: {
      city_name: '',
      order: 5
    }
  }
})

export default {
  data() {
    return {
      form: {
        ...formDefaultKeys()
      }
    }
  },

  computed: {
    ...mapState(['reportsStore', 'filterDialog'])
  },

  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER', 'SET_QUERY_PARAM']),

    onCancel() {
      this.form = {
        ...formDefaultKeys()
      }

      const params = this.combineParams()

      this.$parent.$emit('filter:form', params)
      this.hideModal()
    },

    onSubmit() {
      try {
        const params = this.combineParams()

        this.$parent.$emit('filter:form', params)
      } catch (err) {
        //
      }

      this.hideModal()
    },

    hideModal() {
      const modalWrapper = document.querySelector('.el-dialog__wrapper')

      if (modalWrapper) modalWrapper.scrollTop = 0

      this.FILTER_DIALOG_TOGGLE(false)
    },

    combineParams() {
      const { destinations, ...props } = this.form

      // console.log('props', props)

      let params = takeFilledKeyValue(Object.freeze({ ...props }))

      // console.log('params', params)

      const t = (Object.values(destinations || {}) || []).filter(
        (item) => item.city_name
      )

      if (t != 0) {
        params.destinations = JSON.stringify(t)
      }
      return params
    }
  }
}
</script>

<style>
/* .filter-body { */
/* border: 1px solid red; */
/* } */

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

[type='text']::-webkit-search-cancel-button,
[type='text']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
</style>
