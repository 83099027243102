<template>
  <div class="reports-toolbar custom--toolbar--btns px-4">
    <div class="mr-2">
      <v-row class="mx-0 align-items-center">
        <div class="mr-4">
          <v-menu
            ref="yearMenuRef"
            v-model="menu.year"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Hisobot yili"
                :nudge-right="40"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
                :value="filter.year && `${filter.year}-01-01` | yearOnly"
                @input="onInput(arguments[0], 'year')"
              />
            </template>
            <v-date-picker
              ref="yearPickerRef"
              :value="`${filter.year || currentYear}-01-01`"
              locale="ru"
              type="year"
              :max="new Date().toISOString().substr(0, 10)"
              min="2010-01-01"
              @click:year="save(arguments[0], 'year')"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="mr-4">
          <v-menu
            ref="yearMonthRef"
            v-model="menu.month"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="ml-2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Oy"
                :nudge-right="40"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
                :value="
                  filter.month &&
                  `${filter.year || currentYear}-${filter.month || '01'}-01`
                    | monthOnly
                "
                hide-details
                clearable
                @input="onInput(arguments[0], 'month')"
              />
            </template>
            <v-date-picker
              ref="monthPickerRef"
              :value="`${filter.year || currentYear}-${
                filter.month || '01'
              }-01`"
              locale="ru"
              no-title
              type="month"
              min="2010-01-01"
              no-date-picker-header
              @click:month="save(arguments[0], 'month')"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="mr-1">
          <div class="d-flex align-items-center">
            <el-button
              style="
                min-width: 5rem;
                height: 2.5rem;
                background-color: #ecf5ff;
                color: #409eff;
              "
              @click="onSearchByDate"
            >
              Izlash
            </el-button>
          </div>
        </div>
      </v-row>
    </div>
    <div class="d-flex align-items-center">
      <el-button
        style="
          min-width: 5rem;
          height: 2.5rem;
          background-color: #ffa800;
          color: white;
        "
        @click="FILTER_DIALOG_TOGGLE(true)"
        >Filter</el-button
      >

      <toExcel
        @excel-download-emit="$emit('excel-download-emit', arguments[0])"
      />
    </div>
    <FilterDialog />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import FilterDialog from './filters'
import toExcel from './toExcel'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

import { takeFilledKeyValue } from '@/utils'
import { monthOnly, yearOnly } from '@/utils/filters/dateFormats.js'

export default {
  components: {
    FilterDialog,
    toExcel
  },

  filters: {
    monthOnly,
    yearOnly
  },

  watch: {
    'menu.year': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.yearPickerRef.activePicker = 'YEAR'))
      }
    },
    'menu.month': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.monthPickerRef.activePicker = 'MONTH'))
      }
    }
  },

  data() {
    return {
      currentYear,

      menu: {
        year: false,
        month: false
      },

      filter: {
        year: +this.$route.query.year || currentYear,
        month: +this.$route.query.month || currentMonth
      }
    }
  },

  // beforeDestroy() {
  //   this.SET_FILTER({
  //     filter: {
  //       marka: '',
  //       get_truck_number: '',
  //       insurance: {
  //         start_date: '',
  //         end_date: ''
  //       },
  //       technical_monitoring: {
  //         start_date: '',
  //         end_date: ''
  //       },
  //       advert_permit: {
  //         start_date: '',
  //         end_date: ''
  //       },
  //       truck_oil_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       },
  //       truck_tire_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       },
  //       truck_accumulator_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       },
  //       truck_kolodka_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       }
  //     },
  //     num: '4'
  //   })
  // },

  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER']),

    onSearchByDate() {
      const props = takeFilledKeyValue(this.filter)

      // console.log('props', props)
      this.$emit('filter:dates', props)
    },

    save(dateTimeValue, datePickerPropKey) {
      // console.log(dateTimeValue, datePickerPropKey)
      let t = dateTimeValue

      if (datePickerPropKey == 'month' && t) {
        t = parseInt(dateTimeValue.slice(5))
      }

      this.filter[datePickerPropKey] = t
    },

    onInput(t = null, arg) {
      // console.log(t, arg)
      this.filter[arg] = t

      // for emit filter props to parent
      this.onSearchByDate()
    }
  }
}
</script>

<style>
.reports-toolbar {
  /* border: 1px solid #01534f; */
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.custom--toolbar--btns .el-button {
  /* padding: 0px !important;
  padding: 0 0.5rem !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid transparent;
}

.reports-toolbar > i,
.el-icon-search::before {
  color: white;
}
</style>
